body {
  /* background-color: #f2f2f2!important; */
}
.qs-project-main {
  background-color: #f2f2f2;
}

.custom-card-fetch-projects:hover,
.custom-card-fetch-projects-closed:hover {
  cursor: pointer;
  transform: translateY(-10px);
  /* background-color: #bda585 !important; */
}

/* Poppins font for pop ups */

.poppins-font {
  font-family: "Poppins", sans-serif;
}

.swal2-popup {
  font-family: "Poppins", sans-serif;
}

/* Footer for Internal Team */
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #f2f2f2;
  opacity: 1;
  padding: 10px 0;
  text-align: center;
  z-index: 1000;
}
/* Footer for project Page */
.footerProject {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #f2f2f2;
  opacity: 1;
  padding: 2px 0;
  text-align: center;
  z-index: 1000;
}

/* Base Logistics container - Create Project Form */
.containerx {
  font-family: "Poppins", sans-serif;
}

.header {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.item {
  flex: 1 1 calc(25% - 16px);
  box-sizing: border-box;
}

@media (max-width: 1300px) {
  .item {
    flex: 1 1 calc(50% - 16px);
  }
}

@media (max-width: 600px) {
  .item {
    flex: 1 1 100%;
  }
}

.button-container {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.btn {
  background-color: #f2f2f2;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
}

.cancel-btn {
  background-color: #f0f0f0 !important;
}

.update-btn {
  background-color: #f0f0f0 !important;
}

.submit-btn {
  background-color: #f0f0f0 !important;
  border: 1px solid transparent;
}

.submit-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.custom-card-fetch-projects {
  background-color: #e8f9ed !important;

  /* border-bottom: 10px solid #dcece1 !important; */
  border-radius: 5px !important;

  /* background-color: #E8F9ED !important; */
}

/* .published-card-style {
  min-height: 9.5rem;
  min-width: 16.5rem;
} */

.custom-card-fetch-projects-closed {
  background-color: #ffe6dc !important;
  /* border-bottom: 10px solid #ebd1be !important; */
}

/* sweet alert team pop up */
.swal2-smaller-popup {
  border-radius: 10px;
  max-width: 400px;
  text-align: left;
}
.swal2-content {
  text-align: left;
}
/* sweet alert team pop up */

.header-logo {
  font-size: 18px;
  font-weight: 500;

  color: #fca200 !important;
  padding-inline: 5px;
}

#navbar-name {
  font-family: Arial, Helvetica, sans-serif !important;
}

.navbar-custom-styles {
  position: absolute !important;
  top: 0;
  width: 100%;
  padding-right: 50px !important;
  padding-left: 20px !important;
  background-color: #f0f0f0 !important;
}
.card-image-styling {
  object-fit: fill !important;
}

.milestone-date {
  min-width: 15rem !important;
}

.transparent-border {
  border: 1px solid transparent !important;
  color: black !important;
}

.transparent-border .MuiOutlinedInput-notchedOutline {
  border: 1px solid transparent !important;
  color: black !important;
}

.transparent-border:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid transparent !important;
  color: black !important;
}

/* ServiceSelect.css */
.service-select-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.custom-datepicker {
  height: 2.6rem !important;
  width: 98%;
  margin-left: 0.6rem;
  border: 1px solid #8b8b8b;
  border-radius: 5px;
}

.small-placeholder {
  font-size: 0.1rem !important;
}

.custom-modal-width .modal-dialog {
  max-width: 50rem !important; /* Adjust the width as needed */
}

/* .select-dropdown {
  width: 15rem !important;
} */

.select-tags ::placeholder {
  font-family: "Poppins" !important;
  opacity: 0.5;
  font-size: 13px;
}

.select-dropdown .MuiInputBase-root {
  height: 45px;
}

.select-dropdown .MuiAutocomplete-inputRoot {
  padding: 5px;
}

.select-dropdown .MuiOutlinedInput-input {
  padding: 10px;
}

.select-dropdown .MuiInputLabel-root {
  top: -5px;
}

.select-dropdown .MuiIconButton-root {
  padding: 5px;
}

.select-client-dropdown .MuiInputBase-root {
  height: 2.6rem !important;
}

.select-client-dropdown {
  width: 18rem !important;
}

.select-client-dropdown .react-dropdown-select-dropdown {
  border-bottom: 20px solid #f2f2f2;
}

.dropdown-button-style {
  z-index: 999;
  position: absolute;
}

/* Project-details-page-styling */
.project-details-main {
  margin-top: 300px;
}

.project-details-img {
  width: 250px;
  height: auto;
  border-radius: 15px;
}

.project-details-main {
  display: flex;
  flex-direction: column;
}

.project-details-header {
  display: flex;
  margin-top: 100px;
}

.project-details-title {
  flex: 1;
}

.project-details-description {
  display: flex;
}

.project-details-details {
  display: flex;
  flex-direction: column;
}

.mouse-cursor-pointer {
  cursor: pointer;
}

.testing-border {
  border: 0.1px dashed black;
  /* padding: 10px 30px 10px; */
}

.max-width-setup {
  max-width: fit-content;
}

.edit-button-styling {
  background-color: #f2f2f2;
  border: 1px solid black;
  border-radius: 1px;
}

/* management-team-styling starts */

.management-team-styling {
  margin-top: 1rem;
  /* text-decoration: underline; */
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
}

/* management-team-styling ends */

/* google-drive-folder starts*/

.google-drive-folder {
  margin-right: 100px;
  max-width: 210px;
  min-width: 200px;
  margin-top: 10px;
  background-color: rgb(161, 216, 161) !important;
}

.setup-drive-folder {
  max-width: fit-content;
  border: 0.5px dashed black;
  background-color: #f2f2f2;
}

.setup-drive-folder:hover {
  max-width: fit-content;
  cursor: pointer;
  background-color: rgb(184, 184, 85);
}

.google-drive-folder:hover {
  cursor: pointer;
  transform: translateY(-10px);
  background-color: #f0b86e !important;
}

/* google-drive-folder ends*/

/* Login-page-styling starts */

/* Login-page-styling ends */

/* Create Project page styling starts */
.select-dropdown-styling {
  width: 300px !important;
}

.create-project-part-one {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.create-project label,
.create-project input,
.create-project select {
  font-size: 12px;
  font-weight: 400;
  font-family: "Poppins";
  margin-bottom: 2px;
  color: #9a9ea9;
}

/* Create Project page styling ends */

.custom-grid {
  margin-top: -1.5rem;
  margin-right: -1.5rem;
  margin-left: -1.5rem;
}

.project-image {
  width: 120px;
  height: auto;
}

.project-card-image {
  object-fit: fill !important;
}

.industry-container {
  display: flex;
  flex-direction: column;
  margin-top: -20%;
}

.industry-chips {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}

.text-ash {
  font-family: Poppins;
  font-weight: 600;
  font-size: 12px;
  color: #606060;
}

.drive-folders .card {
  color: #8b8b8b;
  background: #f3f3f3;
  border: 0;
  border-radius: 12px;
  padding: 5px;
  max-width: 15rem;
}
.drive-folders .card .card-body {
  background: #ffffff;
  border-radius: 10px;
}
.drive-folders .card .card-body img {
  margin: 15px;
}
.drive-folders .card h6 {
  font-size: 14px;
  color: #999999;
}

.custom-quill .ql-container {
  min-height: 10rem;
}

.custom-quill .ql-editor {
  min-height: 10rem;
}

.custom-quill .ql-toolbar {
  border-bottom: 1px solid transparent;
}

/* Finance Tab Sliding texts */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-30px);
  }
}

.loading-text {
  animation: slideIn 0.5s forwards;
}

.loading-text-exit {
  animation: slideOut 0.5s forwards;
}


